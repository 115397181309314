<template>
  <div class="navbar-brand">
    <div class="navbar-item">
      <router-link to="/">
        <img src="@/assets/img/icon_bronte_head_lg.png" />
      </router-link>
    </div>
    <div class="navbar-item nav-home">
      <span class="tour">Bronte Tax Documents</span>
    </div>
          <!-- aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample" -->

    <div
      class="navbar-burger"
      @click="$emit('update:modelValue',toggleBurger(modelValue))" :class="{ 'is-active': modelValue }">
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        toggleBurger(showNav) {
          return showNav = !showNav
        }
    }
}
</script>