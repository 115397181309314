<template>
  <header>
    <nav
      class="navbar is-transparent"
      role="navigation"
      aria-label="main navigation"
    >
      <HeaderBarBrand v-model="showNav" />
      <HeaderBarLinks :showNav="showNav" />
    </nav>
  </header>
</template>

<script>
import HeaderBarBrand from '@/components/header-bar-brand';
import HeaderBarLinks from '@/components/header-bar-links';
export default {
  name: 'HeaderBar',
  components: { HeaderBarBrand, HeaderBarLinks },
  data() {
      return {
          showNav: false
      }
  },
};
</script>

<style>
    @import '../assets/css/bulma.css';
</style>