<template>
  <div class="navbar-menu" :class="{ 'is-active': showNav }">
    <div class="navbar-end">
      <!-- <div class="navbar-item">
        <router-link to="/authenticate">Authentication</router-link>
      </div> -->
      <div class="navbar-item" v-if="uploadRights">
        <router-link to="/uploaddocs">Upload Documents</router-link>
      </div>
      <div class="navbar-item" v-if="account">
        <router-link to="/viewdocs">View Documents</router-link>
      </div>
      <div class="navbar-item">
        <div class="buttons">
          <div class="button is-expanded is-primary"
            v-if="!account"
            @click="login"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fas fa-sign-in-alt fa-2x" aria-hidden="false">Login</i>
          </div>
          <div v-else class="button is-expanded is-primary" @click="logout" target="_blank" rel="noopener noreferrer">
            <i class="fas fa-sign-out-alt fa-2x" aria-hidden="false">Logout</i>
          </div>
          <!-- <div v-if="account">{{ account.name }}</div> -->
          
        </div>
      </div>
      <div class="navbar-item" v-if="account">
          <span class="button is-rounded is-disabled">Welcome {{ account.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/msal2-authService';
import { mapMutations } from 'vuex';
// import { PublicClientApplication } from '@azure/msal-browser';
export default {
  name: 'HeaderBar',
  props: {
        showNav: {
            type: Boolean
        }
  },
  data() {
    return {
      account: this.$store.state.account,
      theToken: this.$store.state.accessToken,
      uploadRights: this.$store.state.uploadRights
    };
  },
  created() {
      this.authService = new AuthService();     
  },
  mounted() {
    
  },
  methods: {
    ...mapMutations(['setAccessToken'],['setUploadRights'],['setStaffID'],['setMSALAccount']),
    async getGroups() {
      this.groups = await this.authService.getGroups();
      // console.log(this.groups);
      // this.groups.forEach((value, index) => {
      //   console.log(value.name);
      //   console.log(index);
      //   if (value.name == "Accounting") {
      //     this.$store.set
      //   }
      // });
      for(let i=0; i< this.groups.length; i++) {
        // console.log(this.groups[i].name);
        if ((this.groups[i].name == "Accounting") || (this.groups[i].name == "Business Office")) {
          this.$store.commit('setUploadRights',true);
          this.uploadRights = true;
          break;
        }
      }
    },
    getUserID() {
      let staffid = this.account.username.substring(0, this.account.username.lastIndexOf("@"));
      // console.log(staffid);
      this.$store.commit('setStaffID',staffid);
      // console.log(this.$store.state.staffID);
    },
    async login() {
      try {
        await this.authService.login()
        this.account = this.authService.request.account;
        this.$store.commit('setMSALAccount',this.account);
        this.getGroups();
        this.getUserID();
        // this.loggedIn = true;
        //this.$store.commit('setAccessToken',this.authService.accessToken);
        //console.log(this.authService.idToken.accessToken);
        // console.log(this.account);
        // console.log("before commit");
        // console.log(this.$store.state.accessToken);
        this.$store.commit('setAccessToken', this.authService.idToken.accessToken);
        // console.log("after commit");
        // console.log(this.$store.state.accessToken);
        this.$router.push('viewdocs');
      }
      catch (error) {
        console.log(error);
      }
    },
    async logout() {
      try {
        this.$store.commit('setAccessToken',null);
        this.$store.commit('setStaffID','');
        this.$store.commit('setUploadRights',false);
        this.$store.commit('setMSALAccount',null)
        this.$router.push('home');
        await this.authService.logout()
      }
      catch (error) {
        console.log(error)
      }
    },
    // async SignIn() {
    //   await this.$msalInstance
    //     .loginPopup({})
    //     .then(() => {
    //       const myAccounts = this.$msalInstance.getAllAccounts();
    //       this.account = myAccounts[0];
    //       this.$emitter.emit('login', this.account);
    //     })
    //     .catch(error => {
    //       console.error(`error during authentication: ${error}`);
    //     });
    // },
    // async logout() {
      // await this.$msalInstance
      //   .logout({})
      //   .then(() => {
      //     this.$emitter.emit('logout', 'logging out');
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    // },
  },
};
</script>

<style scoped>
.button.is-primary {
  background-color: #891a1c;
}
</style>