import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
// import Vuex from 'vuex';
// import Vue from 'vue';
// import createPersistedState from "vuex-persistedstate";
// import auth from './modules/auth';

// // Load Vuex
// Vue.use(Vuex);
// // Create store
// export default new Vuex.Store({
//   modules: {
//     auth
//   },
//   plugins: [createPersistedState()]
// });

// export default createStore({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {}
// });

const store = createStore({
  state() {
    return {
      // msalConfig: {
      //   auth: {
      //     clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID,
      //     authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_AD_TENANT_NAME}`,
      //   },
      //   cache: {
      //     cacheLocation: 'localStorage',
      //   },
      // },
      accessToken: null,
      uploadRights: false,
      staffID: '',
      account: null
    };
  },
  mutations :{
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setUploadRights(state, rights) {
      state.uploadRights = rights;
    },
    setStaffID(state, staffid) {
      state.staffID = staffid;
    },
    setMSALAccount(state, account) {
      state.account = account;
    }
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
});

export default store;
