import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import Main from "../views/Home.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Main,
  },
  {
    path: "/uploaddocs",
    name: "UploadDocuments",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "UploadDocuments" */ "../views/UploadDocuments.vue"),
    meta: {
      requiresAuth: true,
      specificUsers: true
    }
  },
  {
    path: "/viewdocs",
    name: "ViewDocuments",
    component: () =>
      import(/* webpackChunkName: "ViewDocuments" */ "../views/ViewDocuments.vue"),
      meta: {
        requiresAuth: true
      }
  }
  // {
  //   path: "/authenticate",
  //   name: "Authenticate",
  //   component: () =>
  //     import(/* webpackChunkName: "Authenticate" */ "../views/Authentication.vue")
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    //console.log("before nav");
    //console.log(store.state.accessToken);
    if (store.state.accessToken == null) {
      //console.log("no token");
      next( {
        name: "Home"
      })
    } else {
      if (to.matched.some(record => record.meta.specificUsers)) {
        if (store.state.uploadRights) {
          next()
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
}
);
export default router;
