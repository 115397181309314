<template>
  <div id="container">
    <HeaderBar />
    <!-- <TheNavigation /> -->
    <router-view />
    <!-- <button @click.prevent="login">Login</button>
    <button @click.prevent="getGroups">Get Groups</button>
    <br />
    <h2>You are <span v-if="!loggedIn">not</span> logged in</h2>
    <ul>
      <li v-for="group in groups" :key="group.id">
        {{group.name}}
      </li>
    </ul> -->
  </div>
</template>

<script>
import HeaderBar from '@/components/header-bar.vue';
// import TheNavigation from "@/components/TheNavigation.vue"
import AuthService from '@/services/msal2-authService';

export default {
    name: "App",
    data() {
        return {
          loggedIn: false,
          groups: []
        }
    },
    created() {
    this.authService = new AuthService();
    },
    methods: {
      getGroups: async function() {
        this.groups = await this.authService.getGroups();
      },
      login: async function() {
        try {
          await this.authService.login();
          this.loggedIn = true;
        }
        catch (error) {
          console.log(error);
        }
      }
    },
    components: {
      HeaderBar
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #502c2c;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>